const state = {
  // 课程名称
  courseName: null,
  // 课程有效期
  expiredTime: null,
  // 学习进度
  learningProcess: null,
  // courseId
  courseId: null,
  // enrollId
  enrollId: null,
  // 业务线
  dicKey: null,
  // 工种
  categoryId: null,
  // stuNo
  stuNo: null,
}
const mutations = {
  saveCourseName: (state, courseName) => {
    state.courseName = courseName
  },
  saveExpiredTime: (state, expiredTime) => {
    state.expiredTime = expiredTime
  },
  saveLearningProcess: (state, learningProcess) => {
    state.learningProcess = learningProcess
  },
  saveCourseId: (state, courseId) => {
    state.courseId = courseId
  },
  saveEnrollId: (state, enrollId) => {
    state.enrollId = enrollId
  },
  saveBizLine: (state, dicKey) => {
    state.dicKey = dicKey
  },
  asveCategoryId: (state, categoryId) => {
    state.categoryId = categoryId
  },
  saveStuNo: (state, stuNo) => {
    state.stuNo = stuNo
  },
}
const getter = {
  count: state => state,
}
export default {
  namespaced: true,
  state,
  mutations,
  getter,
}
