const Jwt = require('jsonwebtoken')
const { jwt_secret, jwt_expires } = require('../const/config').jwt

exports.generateJwtToken = function(rule) {
  let token
  if (rule) {
    token = Jwt.sign(rule, jwt_secret, { expiresIn: jwt_expires })
  } else {
    token = Jwt.sign({ jwtToken: jwt_secret }, jwt_secret, { expiresIn: jwt_expires })
  }
  return token
}
