<template>
  <div class="question">
    <div class="header">
      <div>
        <!--        上机考试一共需要完成{{ paperData.length }}套试卷； 目前为试卷{{ paperIndex + 1 }}:-->
        {{ title }}
      </div>
      <div class="flex-box jc-sb ai-c">
        <div>{{ realName }}</div>
        <div class="cardNum">准考证号:{{ examCardNum }}</div>
      </div>
    </div>
    <div class="content">
      <div class="left">
        <!-- 单选题 -->
        <div v-if="active <= singleChoiceList.length">
          <div class="first">单选题（共{{
              singleChoiceList.length
            }}题，{{ singleChoiceList.length > 0 ? singleChoiceList[0].point : 0 }}分/题）
          </div>
          <ul>
            <li v-for="(item, index) in singleChoiceList" v-if="active === index + 1" :key="index">
              <div class="second">{{ active }}.{{ item.title }}</div>
              <div class="third">
                <el-radio-group v-model="item.own" @change="changeChiose(item, index)">
                  <el-radio v-for="(val, index) in item.answerOptions" :key="index" :label="val.option">{{
                      val.option
                    }}.{{ val.value }}
                  </el-radio>
                </el-radio-group>
              </div>
              <div class="fourth">
                <el-button v-if="active > 1" type="primary" @click="backClick">上一题</el-button>
                <el-button
                  v-if="active < singleChoiceList.length + TrueFalseList.length + MultiChoiceList.length + exampleList.length"
                  type="primary"
                  @click="nextClick"
                >下一题
                </el-button>
              </div>
            </li>
          </ul>
        </div>
        <!-- 多选题 -->
        <div v-else-if="active <= singleChoiceList.length + MultiChoiceList.length && active > singleChoiceList.length">
          <div class="first">多选题（共{{
              MultiChoiceList.length
            }}题，{{ MultiChoiceList.length > 0 ? MultiChoiceList[0].point : 0 }}分/题）
          </div>
          <ul>
            <li
              v-for="(item, index) in MultiChoiceList"
              v-if="active === singleChoiceList.length + index + 1"
              :key="index"
            >
              <div class="second">{{ active }}.{{ item.title }}</div>
              <div class="third">
                <el-checkbox-group v-model="item.own" @change="changeChiose(item, index)">
                  <el-checkbox v-for="(val, index) in item.answerOptions" :key="index" :label="val.option">{{
                      val.option
                    }}.{{ val.value }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
              <div class="fourth">
                <el-button v-if="active > 1" type="primary" @click="backClick">上一题</el-button>
                <el-button
                  v-if="active < singleChoiceList.length + TrueFalseList.length + MultiChoiceList.length + exampleList.length"
                  type="primary"
                  @click="nextClick"
                >下一题
                </el-button>
              </div>
            </li>
          </ul>
        </div>
        <!-- 判断题 -->
        <div
          v-else-if="active <= singleChoiceList.length + TrueFalseList.length + MultiChoiceList.length && active > singleChoiceList.length + MultiChoiceList.length"
        >
          <div class="first">判断题（共{{ TrueFalseList.length }}题，{{
              TrueFalseList.length > 0 ? TrueFalseList[0].point : 0
            }}分/题）
          </div>
          <ul>
            <li
              v-for="(item, index) in TrueFalseList"
              v-if="active === singleChoiceList.length + MultiChoiceList.length + index + 1"
              :key="index"
            >
              <div class="second">{{ active }}.{{ item.title }}</div>
              <div class="third">
                <el-radio-group v-model="item.own" @change="changeChiose(item, index)">
                  <el-radio label="true">正确</el-radio>
                  <el-radio label="false">错误</el-radio>
                </el-radio-group>
              </div>
              <div class="fourth">
                <el-button v-if="active > 1" type="primary" @click="backClick">上一题</el-button>
                <el-button
                  v-if="active < singleChoiceList.length + TrueFalseList.length + MultiChoiceList.length + exampleList.length"
                  type="primary"
                  @click="nextClick"
                >下一题
                </el-button>
              </div>
            </li>
          </ul>
        </div>
        <!-- 案例题 -->
        <div
          v-else-if="active <= singleChoiceList.length + TrueFalseList.length + MultiChoiceList.length + exampleList.length && active > singleChoiceList.length + TrueFalseList.length + MultiChoiceList.length"
        >
          <div class="first">案例题（共{{ exampleList.length }}题，{{
              exampleList.length > 0 ? exampleList[0].point : 0
            }}分/题）
          </div>
          <ul>
            <li
              v-for="(item, index) in exampleList"
              v-if="active === MultiChoiceList.length + TrueFalseList.length + singleChoiceList.length + index + 1"
              :key="index"
            >
              <div class="second">{{ active }}.{{ item.title }}</div>
              <div v-for="(val, i) in item.children" :key="i" class="third">
                <div class="second">{{ i + 1 }}.{{ val.title }}</div>
                <el-radio-group
                  v-if="val.type === QUESTION_TYPE.SingleChoice.val"
                  v-model="val.own"
                  @change="changeChiose(val, index)"
                >
                  <el-radio v-for="(a, b) in val.answerOptions" :key="b" :label="a.option">{{ a.option }}.{{
                      a.value
                    }}
                  </el-radio>
                </el-radio-group>
                <el-radio-group
                  v-if="val.type === QUESTION_TYPE.TrueFalse.val"
                  v-model="val.own"
                  @change="changeChiose(val, index)"
                >
                  <el-radio label="true">正确</el-radio>
                  <el-radio label="false">错误</el-radio>
                </el-radio-group>
                <el-checkbox-group
                  v-if="val.type === QUESTION_TYPE.MultiChoice.val"
                  v-model="val.own"
                  @change="changeChiose(val, index)"
                >
                  <el-checkbox v-for="(a, b) in val.answerOptions" :key="b" :label="a.option">{{ a.option }}.{{
                      a.value
                    }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
            </li>
          </ul>
          <div class="fourth">
            <el-button v-if="active > 1" type="primary" @click="backClick">上一题</el-button>
            <el-button
              v-if="active < singleChoiceList.length + TrueFalseList.length + MultiChoiceList.length + exampleList.length"
              type="primary"
              @click="nextClick"
            >下一题
            </el-button>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="card-header">答题卡 <span>(共{{
            singleChoiceList.length + TrueFalseList.length + MultiChoiceList.length + exampleList.length
          }}题，总分{{ totalScore }}分，{{ passScore }}分合格)</span></div>
        <div class="btn-wrapper">
          <div class="submit" @click="submitClick">交卷</div>
          <div class="time"><i class="icon el-icon-time"/><span style="margin-left: 10px">离考试结束：{{ dowmTime
            }}</span>
          </div>
        </div>
        <div class="number">
          <div class="qus-type">单选题（{{ singleChoiceList.length > 0 ? singleChoiceList[0].point : 1 }}分/题）</div>
          <ul>
            <li
              v-for="(item, index) in singleChoiceList"
              :key="index"
              :class="item.own?'blue':''"
              @click="singleChoiceChange(item,index)"
            >{{ index + 1 }}
            </li>
          </ul>
          <div class="qus-type">多选题（{{ MultiChoiceList.length > 0 ? MultiChoiceList[0].point : 2 }}分/题）</div>
          <ul>
            <li
              v-for="(item, index) in MultiChoiceList"
              :key="index"
              :class="item.own.length>0?'blue':''"
              @click="multiChoiceChange(item,index)"
            >{{ index + 1 + singleChoiceList.length }}
            </li>
          </ul>
          <div class="qus-type">判断题（{{ TrueFalseList.length > 0 ? TrueFalseList[0].point : 1 }}分/题）</div>
          <ul>
            <li
              v-for="(item, index) in TrueFalseList"
              :key="index"
              :class="item.own?'blue':''"
              @click="trueFalseChange(item,index)"
            >{{ index + 1 + singleChoiceList.length + MultiChoiceList.length }}
            </li>
          </ul>
          <div v-if="exampleList.length !== 0" class="qus-type">案例题（{{
              exampleList.length > 0 ? exampleList[0].point : 5
            }}分/题）
          </div>
          <ul v-if="exampleList.length !== 0">
            <li
              v-for="(item, index) in exampleList"
              :key="index"
              :class="checkExampleAnswer(item)?'blue':''"
              @click="exampleChange(item,index)"
            >
              {{ index + 1 + singleChoiceList.length + MultiChoiceList.length + TrueFalseList.length }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations} from 'vuex'
import {getPaperInfo, getSessionsInfo, singleSubmitAnswer, submitPaper} from '@/api/exam'
import {PAPER_TYPE, QUESTION_TYPE} from '@/const/sys'
import moment from '@/utils/moment'
import {_local} from '@/utils/local'
import {getCurrentExamRecord} from "@/utils/auth";

export default {
  name: 'ExaminationDetailTab',
  data() {
    return {
      QUESTION_TYPE,
      title: '',
      examCardNum: '', // 准考证号
      realName: '', // 姓名
      // 当前做到哪个题目
      active: 1,
      // 单选题列表
      singleChoiceList: [],
      // 多选列表
      MultiChoiceList: [],
      // 判断列表
      TrueFalseList: [],
      // 案例题列表
      exampleList: [],
      // 总题数
      total: [],
      // 倒计时
      timer: null,
      timerAll: null,
      // 倒计时时间
      dowmTime: '',
      // 答题卡
      sheetNo: null,
      // 考试编号
      enrollExamId: 0,
      examRecordId: 0,
      totalScore: 0,
      passScore: 0,
      paperData: [],
      paperType: '', // 考卷类型(废弃)
      paperIndex: -1, // 当前正在处理的考卷编号
    }
  },
  watch: {
    active(newValue, oldValue) {
      const _idx = oldValue - 1
      const _quesInfo = this.total[_idx]
      switch (_quesInfo.type) {
        case QUESTION_TYPE.SingleChoice.val: {
          const _userAnswer = this.singleChoiceList[_idx].own
          if (_userAnswer) {
            this.singleSubmitItem(this.singleChoiceList[_idx])
          }
        }
          break
        case QUESTION_TYPE.MultiChoice.val: {
          const _mulIdx = _idx - this.singleChoiceList.length
          this.singleSubmitItem(this.MultiChoiceList[_mulIdx])
        }
          break
        case QUESTION_TYPE.TrueFalse.val: {
          const _trueIdx = _idx - this.singleChoiceList.length - this.MultiChoiceList.length
          this.singleSubmitItem(this.TrueFalseList[_trueIdx])
        }
          break
        case QUESTION_TYPE.example.val: {
          const _exampleIdx = _idx - this.singleChoiceList.length - this.MultiChoiceList.length - this.TrueFalseList.length
          const _list = this.exampleList[_exampleIdx]
          _list.children.forEach((item, index) => {
            this.singleSubmitItem(item)
          })
        }
          break
      }
    },
  },
  mounted() {
    this.init()
  },
  created() {
    this.$nextTick(() => {
      // 禁用右键
      document.oncontextmenu = new Function('event.returnValue=false')
      // 禁用选择
      document.onselectstart = new Function('event.returnValue=false')
    })
  },
  methods: {
    ...mapMutations('stuExam', [
      'saveTextResult',
    ]),
    init() {
      getSessionsInfo().then(rst => {
        if (rst.success) {
          if (rst.data.length > 0) {
            // 找到选择考试的试卷
            const _paperIndex = rst.data.findIndex(m => m.examRecordId === getCurrentExamRecord().examRecordId)
            if (_paperIndex >= 0) {
              this.paperIndex = _paperIndex
              const _data = rst.data[_paperIndex]
              // this.title = '【' + _data.paperTitle + '】'
              this.realName = _data.realName
              this.title = _data.paperTitle
              this.sheetNo = _data.sheetNo
              this.enrollExamId = _data.enrollExamId
              this.examRecordId = _data.examRecordId
              this.examCardNum = _data.examCardNum
              this.totalScore = _data.fullScore
              this.passScore = _data.passScore
              let _isStart = true // 是否允许开始考试
              let _errMsg = ''
              if (moment(_data.examStartTime) > moment()) {
                _isStart = false
                _errMsg = '考试未开始'
              } else if (moment(_data.examEndTime) < moment()) {
                _isStart = false
                _errMsg = '考试已结束'
              }
              if (_isStart) {
                let _userExamStartTime = _data.userExamStartTime
                if (!_userExamStartTime) {
                  _userExamStartTime = moment().format('YYYY-MM-DD HH:mm:ss')
                }
                // 开始倒计时每场60分钟
                this.countDown(_userExamStartTime, _data.examEndTime)
                this.createPaper(_data.paperNo, _data.examRecordId, _data.stuNo)
              } else {
                this.$alert(_errMsg, '提示', {
                  confirmButtonText: '知道了',
                  showClose: false,
                  callback: action => {
                    window.location.replace('/')
                  },
                })
              }
            } else {
              this.$alert('考卷已提交', '提示', {
                confirmButtonText: '知道了',
                showClose: false,
                callback: action => {
                  window.location.replace('/')
                },
              })
            }
          } else {
            this.$alert('暂无考试计划', '提示', {
              confirmButtonText: '知道了',
              showClose: false,
              callback: action => {
                window.location.replace('/')
              },
            })
          }
        }
      })
    },
    // 创建考卷
    createPaper(paperNo, examRecordId, stuNo) {
      getPaperInfo({
        paperNo,
        examRecordId,
        stuNo,
      }).then(res => {
        if (res.success) {
          this.sheetNo = res.data.sheetNo
          const _data = res.data.questionList
          _data.forEach(item => {
            const obj = {}
            // 题目内容
            obj.title = item.title
            // 选项
            const _answerOptions = eval(item.answerOptions)
            obj.answerOptions = _answerOptions
            obj.description = item.description
            // 选中的答案
            obj.own = item.userAnswer || ''
            // 题目类型
            obj.type = item.questionType
            // 题目分数
            obj.point = item.point
            // 题目ID
            obj.questionId = item.questionId
            obj.sheetInfoId = item.answersheetInfoId
            obj.isSubmit = !!item.userAnswer
            switch (item.questionType) {
              case QUESTION_TYPE.SingleChoice.val: {
                this.singleChoiceList.push(obj)
              }
                break
              case QUESTION_TYPE.MultiChoice.val: {
                // 选中的答案
                obj.own = []
                if (item.userAnswer) {
                  item.userAnswer = item.userAnswer.toUpperCase()
                  obj.own = item.userAnswer.split('#')
                  obj.isSubmit = true
                } else obj.isSubmit = false
                _answerOptions.map(item => {
                  item.flag = false
                })
                obj.answerOptions = _answerOptions
                this.MultiChoiceList.push(obj)
              }
                break
              case QUESTION_TYPE.TrueFalse.val: {
                this.TrueFalseList.push(obj)
              }
                break
              case QUESTION_TYPE.example.val: {
                const itemArr = []
                obj.questionId = item.questionId
                item.child.map((itm, index) => {
                  const iobj = {}
                  iobj.no = index + 1
                  iobj.title = itm.title
                  iobj.own = itm.questionType === QUESTION_TYPE.MultiChoice.val ? [] : ''
                  if (itm.userAnswer) {
                    iobj.isSubmit = true
                    iobj.own = itm.questionType === QUESTION_TYPE.MultiChoice.val ? itm.userAnswer.split('#') : itm.userAnswer
                  } else {
                    iobj.isSubmit = false
                  }
                  iobj.seeFlag = false
                  iobj.questionId = itm.questionId
                  iobj.sheetInfoId = itm.answersheetInfoId
                  const earr = eval(itm.answerOptions)
                  earr.map(im => {
                    im.flag = false
                  })
                  iobj.answerOptions = earr
                  iobj.description = itm.description
                  iobj.type = itm.questionType
                  itemArr.push(iobj)
                })
                obj.children = itemArr
                this.exampleList.push(obj)
              }
                break
            }
          })
          this.total = this.singleChoiceList.concat(this.MultiChoiceList).concat(this.TrueFalseList).concat(this.exampleList)
        } else {
          this.$alert(res.msg, '提示', {
            confirmButtonText: '确定',
            showClose: false,
            callback: action => {
              return false
            },
          })
        }
      })
    },
    // 上一题
    backClick() {
      this.active -= 1
    },
    // 下一题
    nextClick() {
      this.active += 1
    },
    // 单选切换
    singleChoiceChange(item, index) {
      this.active = index + 1
    },
    // 判断切换
    trueFalseChange(item, index) {
      this.active = this.singleChoiceList.length + this.MultiChoiceList.length + index + 1
    },
    // 多选切换
    multiChoiceChange(item, index) {
      this.active = this.singleChoiceList.length + index + 1
    },
    // 案例题切换
    exampleChange(item, index) {
      this.active = this.singleChoiceList.length + this.TrueFalseList.length + this.MultiChoiceList.length + index + 1
    },
    // 选择确定
    changeChiose(val, index) {
      val.seeFlag = true
      val.isSubmit = false
      this.total[this.active - 1].class = 'blue'
    },
    // 选择案例题是否完成答题
    checkExampleAnswer(item) {
      let _isSetAnswer = true // 是否已答题完毕
      item.children.forEach(d => {
        if (d.type === QUESTION_TYPE.MultiChoice.val) {
          if (d.own.length === 0) {
            _isSetAnswer = false
          }
        } else if (!d.own) {
          _isSetAnswer = false
        }
      })
      return _isSetAnswer
    },
    // 倒计时
    getFormat(time) {
      const minute = parseInt(time / 60) < 10 ? '0' + parseInt(time / 60) : parseInt(time / 60)
      const second = parseInt(time % 60) < 10 ? '0' + parseInt(time % 60) : parseInt(time % 60)
      return minute + '分' + second + '秒'
    },
    // 倒计时，60分钟后交卷
    countDown(startTime, endTime) {
      const countdownEndTime = moment(endTime).format('YYYY-MM-DD HH:mm:ss')
      // if (startTime) {
      //   // 当存在考卷开始时间时 考试结束时间则为考卷考试时间+1小时
      //   countdownEndTime = moment(moment(startTime).add(1, 'h')).format('YYYY-MM-DD HH:mm:ss')
      // } else if (moment(moment(moment().add(1, 'h')).format('YYYY-MM-DD HH:mm:ss')).isBefore(endTime)) {
      //   // 结束时间大 则 开始时间设置为 当前时间+1小时  强制60分钟
      //   countdownEndTime = moment().add(1, 'h').format('YYYY-MM-DD HH:mm:ss')
      // } else {
      //   // 结束时间小于 加了一个小时的当前时间,则代表 本场考试时间不足1小时，按照实际考试结束时间来
      //   countdownEndTime = moment(endTime).format('YYYY-MM-DD HH:mm:ss')
      // }
      const _this = this
      this.timer = setInterval(() => {
        const time = moment(countdownEndTime).diff(moment().format('YYYY-MM-DD HH:mm:ss'), 'seconds')
        const timeData = _this.getFormat(time)
        _this.dowmTime = timeData
        if (time <= 0) {
          clearInterval(_this.timer)
          _this.autoSubmitPaper()
        }
      }, 1000)
    },
    /**
     * 单题提交
     * @param questionInfo  题目信息
     * @param answerInfo 答案信息
     */
    singleSubmitItem(answerInfo) {
      singleSubmitAnswer({
        sheetInfoId: answerInfo.sheetInfoId,
        userAnswer: answerInfo.type === QUESTION_TYPE.MultiChoice.val ? answerInfo.own.join(('#')) : answerInfo.own,
      }).then(rst => {
        answerInfo.isSubmit = true
      }).catch(err => {
        answerInfo.isSubmit = false
      })
    },

    // 获取未提交的考卷列表
    getNoSubmitInfo() {
      const arr = []
      let _hasNoAnswer = false // 判断是否有未作答的题目
      this.singleChoiceList.forEach(item => {
        if (item.own) {
          if (!item.isSubmit) {
            arr.push(this.singleSubmitItem(item))
          }
        } else {
          _hasNoAnswer = true
        }
      })
      this.TrueFalseList.forEach(item => {
        if (item.own) {
          if (!item.isSubmit) {
            arr.push(this.singleSubmitItem(item))
          }
        } else {
          _hasNoAnswer = true
        }
      })
      this.MultiChoiceList.forEach(item => {
        if (item.own.length > 0) {
          if (!item.isSubmit) {
            arr.push(this.singleSubmitItem(item))
          }
        } else {
          _hasNoAnswer = true
        }
      })
      this.exampleList.forEach(item => {
        item.children.forEach(val => {
          if (item.own && item.own.length > 0) {
            if (!val.isSubmit) {
              arr.push(this.singleSubmitItem(val))
            }
          } else {
            _hasNoAnswer = true
          }
        })
      })
      return {
        hasNoAnswer: _hasNoAnswer,
        data: arr,
      }
    },

    // 交卷
    submitClick() {
      let _msg = '您是否确定交卷？'
      const _data = this.getNoSubmitInfo()
      if (_data.hasNoAnswer) {
        _msg = '试题尚未完成，是否交卷？'
      }
      this.$confirm(_msg, '提示', {
        confirmButtonText: '确定交卷',
        cancelButtonText: '继续答题',
        type: 'alert',
      }).then(() => {
        //const _isEnd = this.paperIndex + 1 >= this.paperData.length
        let _tips = '确认交卷后，考试将会结束，是否交卷?'
        // if (!_isEnd) {
        //   const _nextIndex = this.paperIndex + 1
        //   _tips = '确认交卷后，将接着进行第二套试卷(' + PAPER_TYPE[this.paperData[_nextIndex].examType].txt + ')的考试'
        // }
        this.$confirm(_tips, '提示', {
          confirmButtonText: '确定交卷',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          Promise.all(_data.data).then(rst => {
            this.submitPaperInfo()
          }).catch(err => {
            this.$alert('提交失败，请重试', '提示', {
              confirmButtonText: '知道了',
              callback: action => {
                return false
              },
            })
          })
        }).catch(() => {
          console.log('cancel')
        })
      }).catch(() => {
        return false
      })
    },
    // 自动交卷
    autoSubmitPaper() {
      const _data = this.getNoSubmitInfo()
      Promise.all(_data.data).finally(err => {
        this.submitPaperInfo()
      })
    },
    // 交卷并跳转至结果页
    submitPaperInfo() {
      submitPaper({
        sheetNo: this.sheetNo,
        examRecordId: this.examRecordId,
      }).then(rst => {
        if (rst.success) {
          this.$alert('你已完成交卷，考试结束', '提示', {
            confirmButtonText: '知道了',
            showClose: false,
            callback: action => {
              window.location.replace('/')
            },
          })
          // const _isEnd = this.paperIndex >= this.paperData.length
          // if (_isEnd) {
          //   this.$alert('你已完成交卷，考试结束', '提示', {
          //     confirmButtonText: '知道了',
          //     showClose: false,
          //     callback: action => {
          //       window.location.replace('/')
          //     },
          //   })
          // } else {
          //   window.location.reload()
          // }
        } else {
          this.$alert(rst.msg || '交卷异常，请重试', '提示', {
            confirmButtonText: '确定',
            showClose: false,
            callback: action => {
              return false
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "exam-detail";
</style>
