<template>
  <div class="">
    <ExaminationDetailTab v-if="isTab === 3" @toList="toList" @toResult="toResult" />
    <ExaminationResultTab v-if="isTab === 4" @toList="toList" @toResult="toResult" />
  </div>
</template>

<script>
import ExaminationDetailTab from './components/examination-detail'
import ExaminationResultTab from './components/examination-result'
export default {
  name: 'ExamManagementTab',
  components: {
    ExaminationDetailTab,
    ExaminationResultTab,
  },
  data() {
    return {
      // 显示页面
      isTab: 3,
    }
  },
  methods: {
    // 切换组件
    toTest() {
      this.isTab = 2
    },
    toList() {
      this.isTab = 1
    },
    toDetail() {
      this.isTab = 3
    },
    toResult() {
      this.isTab = 4
    },
  },
}
</script>
