<template>
  <div class="login-container">
    <div class="title-container">
      <div class="title">化工培训</div>
      <div class="title2">—— 考务系统 ——</div>
    </div>
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="loginRules"
      autocomplete="on"
      class="login-form"
      label-position="left"
    >
      <div v-if="!selectPick" class="form-content">
        <el-form-item prop="username">
          <span class="svg-container">
            <svg-icon icon-class="user"/>
          </span>
          <el-input
            ref="username"
            v-model="loginForm.username"
            placeholder="用户名"
            name="username"
            type="text"
            tabindex="1"
            autocomplete="on"
          />
        </el-form-item>

        <el-tooltip v-model="capsTooltip" content="Caps lock is On" placement="right" manual>
          <el-form-item prop="password">
            <span class="svg-container">
              <svg-icon icon-class="password"/>
            </span>
            <el-input
              :key="passwordType"
              ref="password"
              v-model="loginForm.password"
              :type="passwordType"
              placeholder="密码"
              name="password"
              tabindex="2"
              autocomplete="on"
              @keyup.native="checkCapslock"
              @blur="capsTooltip = false"
              @keyup.enter.native="handleLogin"
            />
            <span class="show-pwd" @click="showPwd">
              <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'"/>
            </span>
          </el-form-item>
        </el-tooltip>
        <div style="text-align: center">
          <el-button
            :loading="loading"
            type="primary"
            class="btnStart"
            @click.native.prevent="handleLogin"
          >开始考试
          </el-button>
        </div>
        <div class="countDown">
          <!--          <span>离考试开始还有：</span>-->
          <!--          <span>00:00:01</span>-->
        </div>
        <!--        <div v-if="activeName === 'student'">没有账号？注册一个</div>-->
        <!--        <div class="flex-box ai-c login-bottom">-->
        <!--          <el-button type="text" style="color: #000" @click="openRestPassword">忘记密码</el-button>-->
        <!--          <div v-if="activeName === 'student'" class="rest text-center">-->
        <!--            <span class="line">|</span>-->
        <!--            <el-button type="text" style="color: #000" @click="openRest">个人注册</el-button>-->
        <!--          </div>-->
        <!--        </div>-->

      </div>
    </el-form>
    <div v-if="selectPick" class="tipsDiv">
      <div class="flex flex-row align-center justify-between popDivTitle">
        <div style="font-size: 17px;font-weight: 500;text-align: left;">请选择科目考试</div>
        <div><img src="~@/assets/images/ico_close.png" @click="closePick"></div>
      </div>
      <div class="select-content">
        <div
          v-for="(item, index) in examItems"
          :key="index"
          class="flex flex-row align-center justify-between contentDiv"
        >
          <div>
            <div style="font-weight: bold">{{ item.paperTitle }}</div>
            <div class="examTime">
              考试时间：{{ item.examStartTime }} 至 {{ item.examEndTime }}
            </div>
          </div>
          <div>
            <span style="font-size: 14px;font-weight: 400;line-height: 20px;color: #0486FF;cursor: pointer;"
                  @click="selectExam(item.examRecordId)">进入考试 ></span>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import {validUsername} from '@/utils/validate'
import {removeCustomStorage, setCurrentExamRecord} from '@/utils/auth'
import {BIZLINE, USER_TYPE} from '@/const/sys'
import {getSessionsInfo} from '@/api/exam'
import moment from '@/utils/moment'

export default {
  name: 'Login',
  data() {
    const validateUsername = (rule, value, callback) => {
      if (!validUsername(value)) {
        callback(new Error('请输入手机号'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value.length === 0) {
        callback(new Error('请输入密码'))
      } else {
        callback()
      }
    }
    return {
      selectPick: false,
      examItems: [], // 试卷集合
      isRest: false, // 忘记密码弹框
      isSignUp: false, // 注册弹框
      loginForm: {
        username: '',
        password: '',
      },
      loginRules: {
        username: [{required: true, trigger: 'blur', message: '请输入手机号'}],
        password: [{required: true, trigger: 'blur', validator: validatePassword}],
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      redirect: undefined,
      otherQuery: {},
    }
  },
  // watch: {
  //   $route: {
  //     handler: function(route) {
  //       const query = route.query
  //       if (query) {
  //         this.redirect = query.redirect
  //         this.otherQuery = this.getOtherQuery(query)
  //       }
  //     },
  //     immediate: true,
  //   },
  // },
  created() {
    // window.addEventListener('storage', this.afterQRScan)
  },
  mounted() {
    // if (this.loginForm.username === '') {
    //   this.$refs.username.focus()
    // } else if (this.loginForm.password === '') {
    //   this.$refs.password.focus()
    // }
  },
  destroyed() {
    // window.removeEventListener('storage', this.afterQRScan)
  },
  methods: {
    handleTabsClick(tab, event) {
      this.activeName = tab.name
    },
    checkCapslock(e) {
      const {key} = e
      this.capsTooltip = key && key.length === 1 && (key >= 'A' && key <= 'Z')
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    handleLogin() {
      removeCustomStorage()
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true
          this.$store.dispatch('user/login', {...this.loginForm, ...{userType: USER_TYPE.student.val}})
            .then(() => {
              this.checkExamStatus()
              this.loading = false
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 选择考试科目
    selectExam(examRecordId) {
      const examObj = this.examItems.find(m => m.examRecordId === examRecordId)
      setCurrentExamRecord(examObj)
      let _isStart = true // 是否允许开始考试
      let _errMsg = ''
      if (moment(examObj.examStartTime) > moment()) {
        _isStart = false
        _errMsg = '考试尚未开始'
      } else if (moment(examObj.examEndTime) < moment()) {
        _isStart = false
        _errMsg = '考试已结束'
      }
      if (_isStart) {
        this.$router.push('/exam')
      } else {
        this.$alert(_errMsg, '警告信息', {
          confirmButtonText: '确定',
          showClose: true,
          type: 'warning',
          callback: action => {
          },
        })
      }
    },
    closePick() {
      this.selectPick = false
    },
    checkExamStatus() {
      getSessionsInfo().then(rst => {
        if (rst.success) {
          if (rst.data && rst.data.length > 0) {
            // 找到未提交的考试
            const _data = rst.data.filter(m => {
              return m.endExam === false && (moment(m.examEndTime) > moment())
            })
            this.examItems = _data
            if (_data == null || _data.length === 0) {
              this.$alert('考卷已提交', '提示', {
                confirmButtonText: '确定',
                showClose: true,
                type: 'warning',
                callback: action => {
                },
              })
            } else {
              this.selectPick = true
            }
          } else {
            this.$alert('暂无考试计划', '提示', {
              confirmButtonText: '确定',
              showClose: true,
              type: 'warning',
              callback: action => {
                window.location.replace('/')
              },
            })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
/* 修复input 背景不协调 和光标变色 */

/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg: #283443;
$light_gray: #fff;
$cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-form .el-input input {
    color: $cursor;
  }
}

/* reset element-ui css */
.login-form {
  .el-input {
    display: inline-block;
    width: 85%;
    height: 47px;

    input {
      height: 47px;
      padding: 12px 5px 12px 15px;
      //color: $light_gray; // 默认的
      color: #B4B2B2;
      background: transparent;
      border: 0;
      border-radius: 0;
      -webkit-appearance: none;
      //caret-color: $cursor; // 默认的
      caret-color: #B4B2B2;

      &:-webkit-autofill {
        //box-shadow: 0 0 0 1000px $bg inset !important; // 默认的
        box-shadow: 0 0 0 1000px #F4F4F4 inset !important;
        //-webkit-text-fill-color: $cursor !important; // 默认的
        -webkit-text-fill-color: #B4B2B2 !important;
      }
    }
  }

  .el-form-item {
    color: #454545;
    //background: rgba(0, 0, 0, 0.1); // 默认的
    background: #F4F4F4;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 5px;
  }
}

.login-bottom {
  justify-content: flex-end;
  color: #000000;
  font-size: 14px;

  .line {
    margin: 0 16px;
  }
}

.select-content {
  max-height: 265px;
  overflow: hidden;
  overflow-y: auto;
}

.tipsDiv {
  width: 600px;

  background-color: white;
  position: fixed;
  //top: 252px;
  top: 332px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  border-radius: 10px;
  padding: 10px 30px;
}

.btnStart {
  width: 100%;
  margin-bottom: 30px;
  background-color: #0486FF;
  font-weight: 20px
}

.popDivTitle {
  height: 45px;
  border-bottom: 1px solid #F0F2F5
}

.contentDiv {
  background-color: #F5F6F8;
  margin-top: 20px;
  height: 65px;
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
}

.examTime {
  font-size: 12px;
  font-weight: 400;
  color: #7B7B7B;
  padding-top: 10px
}

</style>

<style lang="scss" scoped>
@import "src/styles/variables";
@import "styles";

</style>
