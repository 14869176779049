const state = {
  upload_token: '',
}

const mutations = {
  SET_UPLOAD_TOKEN: (state, token) => {
    state.upload_token = token
  },
}

const actions = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
