var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "question" }, [
    _c("div", { staticClass: "header" }, [
      _c("div", [_vm._v(" " + _vm._s(_vm.title) + " ")]),
      _c("div", { staticClass: "flex-box jc-sb ai-c" }, [
        _c("div", [_vm._v(_vm._s(_vm.realName))]),
        _c("div", { staticClass: "cardNum" }, [
          _vm._v("准考证号:" + _vm._s(_vm.examCardNum)),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "left" }, [
        _vm.active <= _vm.singleChoiceList.length
          ? _c("div", [
              _c("div", { staticClass: "first" }, [
                _vm._v(
                  "单选题（共" +
                    _vm._s(_vm.singleChoiceList.length) +
                    "题，" +
                    _vm._s(
                      _vm.singleChoiceList.length > 0
                        ? _vm.singleChoiceList[0].point
                        : 0
                    ) +
                    "分/题） "
                ),
              ]),
              _c(
                "ul",
                _vm._l(_vm.singleChoiceList, function (item, index) {
                  return _vm.active === index + 1
                    ? _c("li", { key: index }, [
                        _c("div", { staticClass: "second" }, [
                          _vm._v(_vm._s(_vm.active) + "." + _vm._s(item.title)),
                        ]),
                        _c(
                          "div",
                          { staticClass: "third" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                on: {
                                  change: function ($event) {
                                    return _vm.changeChiose(item, index)
                                  },
                                },
                                model: {
                                  value: item.own,
                                  callback: function ($$v) {
                                    _vm.$set(item, "own", $$v)
                                  },
                                  expression: "item.own",
                                },
                              },
                              _vm._l(item.answerOptions, function (val, index) {
                                return _c(
                                  "el-radio",
                                  { key: index, attrs: { label: val.option } },
                                  [
                                    _vm._v(
                                      _vm._s(val.option) +
                                        "." +
                                        _vm._s(val.value) +
                                        " "
                                    ),
                                  ]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "fourth" },
                          [
                            _vm.active > 1
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: { click: _vm.backClick },
                                  },
                                  [_vm._v("上一题")]
                                )
                              : _vm._e(),
                            _vm.active <
                            _vm.singleChoiceList.length +
                              _vm.TrueFalseList.length +
                              _vm.MultiChoiceList.length +
                              _vm.exampleList.length
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: { click: _vm.nextClick },
                                  },
                                  [_vm._v("下一题 ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ])
                    : _vm._e()
                }),
                0
              ),
            ])
          : _vm.active <=
              _vm.singleChoiceList.length + _vm.MultiChoiceList.length &&
            _vm.active > _vm.singleChoiceList.length
          ? _c("div", [
              _c("div", { staticClass: "first" }, [
                _vm._v(
                  "多选题（共" +
                    _vm._s(_vm.MultiChoiceList.length) +
                    "题，" +
                    _vm._s(
                      _vm.MultiChoiceList.length > 0
                        ? _vm.MultiChoiceList[0].point
                        : 0
                    ) +
                    "分/题） "
                ),
              ]),
              _c(
                "ul",
                _vm._l(_vm.MultiChoiceList, function (item, index) {
                  return _vm.active === _vm.singleChoiceList.length + index + 1
                    ? _c("li", { key: index }, [
                        _c("div", { staticClass: "second" }, [
                          _vm._v(_vm._s(_vm.active) + "." + _vm._s(item.title)),
                        ]),
                        _c(
                          "div",
                          { staticClass: "third" },
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                on: {
                                  change: function ($event) {
                                    return _vm.changeChiose(item, index)
                                  },
                                },
                                model: {
                                  value: item.own,
                                  callback: function ($$v) {
                                    _vm.$set(item, "own", $$v)
                                  },
                                  expression: "item.own",
                                },
                              },
                              _vm._l(item.answerOptions, function (val, index) {
                                return _c(
                                  "el-checkbox",
                                  { key: index, attrs: { label: val.option } },
                                  [
                                    _vm._v(
                                      _vm._s(val.option) +
                                        "." +
                                        _vm._s(val.value) +
                                        " "
                                    ),
                                  ]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "fourth" },
                          [
                            _vm.active > 1
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: { click: _vm.backClick },
                                  },
                                  [_vm._v("上一题")]
                                )
                              : _vm._e(),
                            _vm.active <
                            _vm.singleChoiceList.length +
                              _vm.TrueFalseList.length +
                              _vm.MultiChoiceList.length +
                              _vm.exampleList.length
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: { click: _vm.nextClick },
                                  },
                                  [_vm._v("下一题 ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ])
                    : _vm._e()
                }),
                0
              ),
            ])
          : _vm.active <=
              _vm.singleChoiceList.length +
                _vm.TrueFalseList.length +
                _vm.MultiChoiceList.length &&
            _vm.active >
              _vm.singleChoiceList.length + _vm.MultiChoiceList.length
          ? _c("div", [
              _c("div", { staticClass: "first" }, [
                _vm._v(
                  "判断题（共" +
                    _vm._s(_vm.TrueFalseList.length) +
                    "题，" +
                    _vm._s(
                      _vm.TrueFalseList.length > 0
                        ? _vm.TrueFalseList[0].point
                        : 0
                    ) +
                    "分/题） "
                ),
              ]),
              _c(
                "ul",
                _vm._l(_vm.TrueFalseList, function (item, index) {
                  return _vm.active ===
                    _vm.singleChoiceList.length +
                      _vm.MultiChoiceList.length +
                      index +
                      1
                    ? _c("li", { key: index }, [
                        _c("div", { staticClass: "second" }, [
                          _vm._v(_vm._s(_vm.active) + "." + _vm._s(item.title)),
                        ]),
                        _c(
                          "div",
                          { staticClass: "third" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                on: {
                                  change: function ($event) {
                                    return _vm.changeChiose(item, index)
                                  },
                                },
                                model: {
                                  value: item.own,
                                  callback: function ($$v) {
                                    _vm.$set(item, "own", $$v)
                                  },
                                  expression: "item.own",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: "true" } }, [
                                  _vm._v("正确"),
                                ]),
                                _c("el-radio", { attrs: { label: "false" } }, [
                                  _vm._v("错误"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "fourth" },
                          [
                            _vm.active > 1
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: { click: _vm.backClick },
                                  },
                                  [_vm._v("上一题")]
                                )
                              : _vm._e(),
                            _vm.active <
                            _vm.singleChoiceList.length +
                              _vm.TrueFalseList.length +
                              _vm.MultiChoiceList.length +
                              _vm.exampleList.length
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: { click: _vm.nextClick },
                                  },
                                  [_vm._v("下一题 ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ])
                    : _vm._e()
                }),
                0
              ),
            ])
          : _vm.active <=
              _vm.singleChoiceList.length +
                _vm.TrueFalseList.length +
                _vm.MultiChoiceList.length +
                _vm.exampleList.length &&
            _vm.active >
              _vm.singleChoiceList.length +
                _vm.TrueFalseList.length +
                _vm.MultiChoiceList.length
          ? _c("div", [
              _c("div", { staticClass: "first" }, [
                _vm._v(
                  "案例题（共" +
                    _vm._s(_vm.exampleList.length) +
                    "题，" +
                    _vm._s(
                      _vm.exampleList.length > 0 ? _vm.exampleList[0].point : 0
                    ) +
                    "分/题） "
                ),
              ]),
              _c(
                "ul",
                _vm._l(_vm.exampleList, function (item, index) {
                  return _vm.active ===
                    _vm.MultiChoiceList.length +
                      _vm.TrueFalseList.length +
                      _vm.singleChoiceList.length +
                      index +
                      1
                    ? _c(
                        "li",
                        { key: index },
                        [
                          _c("div", { staticClass: "second" }, [
                            _vm._v(
                              _vm._s(_vm.active) + "." + _vm._s(item.title)
                            ),
                          ]),
                          _vm._l(item.children, function (val, i) {
                            return _c(
                              "div",
                              { key: i, staticClass: "third" },
                              [
                                _c("div", { staticClass: "second" }, [
                                  _vm._v(
                                    _vm._s(i + 1) + "." + _vm._s(val.title)
                                  ),
                                ]),
                                val.type === _vm.QUESTION_TYPE.SingleChoice.val
                                  ? _c(
                                      "el-radio-group",
                                      {
                                        on: {
                                          change: function ($event) {
                                            return _vm.changeChiose(val, index)
                                          },
                                        },
                                        model: {
                                          value: val.own,
                                          callback: function ($$v) {
                                            _vm.$set(val, "own", $$v)
                                          },
                                          expression: "val.own",
                                        },
                                      },
                                      _vm._l(
                                        val.answerOptions,
                                        function (a, b) {
                                          return _c(
                                            "el-radio",
                                            {
                                              key: b,
                                              attrs: { label: a.option },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(a.option) +
                                                  "." +
                                                  _vm._s(a.value) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    )
                                  : _vm._e(),
                                val.type === _vm.QUESTION_TYPE.TrueFalse.val
                                  ? _c(
                                      "el-radio-group",
                                      {
                                        on: {
                                          change: function ($event) {
                                            return _vm.changeChiose(val, index)
                                          },
                                        },
                                        model: {
                                          value: val.own,
                                          callback: function ($$v) {
                                            _vm.$set(val, "own", $$v)
                                          },
                                          expression: "val.own",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: "true" } },
                                          [_vm._v("正确")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: "false" } },
                                          [_vm._v("错误")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                val.type === _vm.QUESTION_TYPE.MultiChoice.val
                                  ? _c(
                                      "el-checkbox-group",
                                      {
                                        on: {
                                          change: function ($event) {
                                            return _vm.changeChiose(val, index)
                                          },
                                        },
                                        model: {
                                          value: val.own,
                                          callback: function ($$v) {
                                            _vm.$set(val, "own", $$v)
                                          },
                                          expression: "val.own",
                                        },
                                      },
                                      _vm._l(
                                        val.answerOptions,
                                        function (a, b) {
                                          return _c(
                                            "el-checkbox",
                                            {
                                              key: b,
                                              attrs: { label: a.option },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(a.option) +
                                                  "." +
                                                  _vm._s(a.value) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      )
                    : _vm._e()
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "fourth" },
                [
                  _vm.active > 1
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.backClick },
                        },
                        [_vm._v("上一题")]
                      )
                    : _vm._e(),
                  _vm.active <
                  _vm.singleChoiceList.length +
                    _vm.TrueFalseList.length +
                    _vm.MultiChoiceList.length +
                    _vm.exampleList.length
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.nextClick },
                        },
                        [_vm._v("下一题 ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "right" }, [
        _c("div", { staticClass: "card-header" }, [
          _vm._v("答题卡 "),
          _c("span", [
            _vm._v(
              "(共" +
                _vm._s(
                  _vm.singleChoiceList.length +
                    _vm.TrueFalseList.length +
                    _vm.MultiChoiceList.length +
                    _vm.exampleList.length
                ) +
                "题，总分" +
                _vm._s(_vm.totalScore) +
                "分，" +
                _vm._s(_vm.passScore) +
                "分合格)"
            ),
          ]),
        ]),
        _c("div", { staticClass: "btn-wrapper" }, [
          _c("div", { staticClass: "submit", on: { click: _vm.submitClick } }, [
            _vm._v("交卷"),
          ]),
          _c("div", { staticClass: "time" }, [
            _c("i", { staticClass: "icon el-icon-time" }),
            _c("span", { staticStyle: { "margin-left": "10px" } }, [
              _vm._v("离考试结束：" + _vm._s(_vm.dowmTime)),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "number" }, [
          _c("div", { staticClass: "qus-type" }, [
            _vm._v(
              "单选题（" +
                _vm._s(
                  _vm.singleChoiceList.length > 0
                    ? _vm.singleChoiceList[0].point
                    : 1
                ) +
                "分/题）"
            ),
          ]),
          _c(
            "ul",
            _vm._l(_vm.singleChoiceList, function (item, index) {
              return _c(
                "li",
                {
                  key: index,
                  class: item.own ? "blue" : "",
                  on: {
                    click: function ($event) {
                      return _vm.singleChoiceChange(item, index)
                    },
                  },
                },
                [_vm._v(_vm._s(index + 1) + " ")]
              )
            }),
            0
          ),
          _c("div", { staticClass: "qus-type" }, [
            _vm._v(
              "多选题（" +
                _vm._s(
                  _vm.MultiChoiceList.length > 0
                    ? _vm.MultiChoiceList[0].point
                    : 2
                ) +
                "分/题）"
            ),
          ]),
          _c(
            "ul",
            _vm._l(_vm.MultiChoiceList, function (item, index) {
              return _c(
                "li",
                {
                  key: index,
                  class: item.own.length > 0 ? "blue" : "",
                  on: {
                    click: function ($event) {
                      return _vm.multiChoiceChange(item, index)
                    },
                  },
                },
                [_vm._v(_vm._s(index + 1 + _vm.singleChoiceList.length) + " ")]
              )
            }),
            0
          ),
          _c("div", { staticClass: "qus-type" }, [
            _vm._v(
              "判断题（" +
                _vm._s(
                  _vm.TrueFalseList.length > 0 ? _vm.TrueFalseList[0].point : 1
                ) +
                "分/题）"
            ),
          ]),
          _c(
            "ul",
            _vm._l(_vm.TrueFalseList, function (item, index) {
              return _c(
                "li",
                {
                  key: index,
                  class: item.own ? "blue" : "",
                  on: {
                    click: function ($event) {
                      return _vm.trueFalseChange(item, index)
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      index +
                        1 +
                        _vm.singleChoiceList.length +
                        _vm.MultiChoiceList.length
                    ) + " "
                  ),
                ]
              )
            }),
            0
          ),
          _vm.exampleList.length !== 0
            ? _c("div", { staticClass: "qus-type" }, [
                _vm._v(
                  "案例题（" +
                    _vm._s(
                      _vm.exampleList.length > 0 ? _vm.exampleList[0].point : 5
                    ) +
                    "分/题） "
                ),
              ])
            : _vm._e(),
          _vm.exampleList.length !== 0
            ? _c(
                "ul",
                _vm._l(_vm.exampleList, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      class: _vm.checkExampleAnswer(item) ? "blue" : "",
                      on: {
                        click: function ($event) {
                          return _vm.exampleChange(item, index)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            index +
                              1 +
                              _vm.singleChoiceList.length +
                              _vm.MultiChoiceList.length +
                              _vm.TrueFalseList.length
                          ) +
                          " "
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }