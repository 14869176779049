// import Cookies from 'js-cookie'
import {_local} from '@/utils/local'

const TokenKey = 'token'
const USERINFO = 'userInfo'
const USERRIGHT = 'userRight'
const RANDOMNUM = 'randomNum'
const CurrentExamRecord = 'currentExamRecord'

// export function getToken() {
//   return Cookies.get(TokenKey)
// }
//
// export function setToken(token) {
//   return Cookies.set(TokenKey, token)
// }
//
// export function removeToken() {
//   return Cookies.remove(TokenKey)
// }

export function setToken(token) {
  return _local.set(TokenKey, token)
}

export function getToken() {
  return _local.get(TokenKey)
}

export function removeToken() {
  return _local.remove(TokenKey)
}

export function getUserInfo() {
  return _local.get(USERINFO)
}

export function setUserInfo(data) {
  return _local.set(USERINFO, data)
}

export function removeUserInfo() {
  return _local.remove(USERINFO)
}

export function getUserRight() {
  return _local.get(USERRIGHT)
}

export function setUserRight(data) {
  return _local.set(USERRIGHT, data)
}

export function removeUserRight() {
  return _local.remove(USERRIGHT)
}

export function setRandomNum(data) {
  return _local.set(RANDOMNUM, data)
}

export function getRandomNum() {
  return _local.get(RANDOMNUM)
}

export function removeRandomNum() {
  return _local.remove(RANDOMNUM)
}

// 设置当前考试信息
export function setCurrentExamRecord(currentExamObj) {
  return _local.set(CurrentExamRecord, currentExamObj)
}

export function getCurrentExamRecord() {
  return _local.get(CurrentExamRecord)
}

export function removeCurrentExamRecord() {
  return _local.remove(CurrentExamRecord)
}

// 移除所有自定义缓存信息
export function removeCustomStorage() {
  removeToken()
  removeUserInfo()
  removeUserRight()
  removeRandomNum()
  removeCurrentExamRecord()
}
