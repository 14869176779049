var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "result" }, [
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "second" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.textResult.result === _vm.EXAM.pass.val
                ? "恭喜，您的练习结果通过"
                : "抱歉，您没有通过考试"
            ) +
            " "
        ),
        _c("i", { staticClass: "icon el-icon-check" }),
      ]),
      _c("div", { staticClass: "exam-info" }, [
        _c("div", { staticClass: "result-box" }, [
          _c("div", { staticClass: "title" }, [_vm._v("试卷得分")]),
          _c("div", { staticClass: "exam-score" }, [
            _c("span", { staticClass: "score" }, [
              _vm._v(_vm._s(_vm.textResult.examScore)),
            ]),
            _vm._v("分"),
          ]),
        ]),
        _c("div", { staticClass: "exam-result" }, [
          _c("div", { staticClass: "result-item hook" }, [
            _c("span", [_vm._v("答对：")]),
            _c("span", [_vm._v(_vm._s(_vm.textResult.countCorrect) + "题")]),
          ]),
          _c("div", { staticClass: "result-item fork" }, [
            _c("span", [_vm._v("答错：")]),
            _c("span", [_vm._v(_vm._s(_vm.textResult.countWrong) + "题")]),
          ]),
          _c("div", { staticClass: "result-item" }, [
            _c("span", [_vm._v("未答：")]),
            _c("span", [_vm._v(_vm._s(_vm.textResult.countEmpty) + "题")]),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticStyle: { "margin-top": "80px" } },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "30%", "margin-bottom": "30px" },
              attrs: { type: "primary" },
            },
            [_vm._v("我知道了")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }