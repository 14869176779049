import request from '@/utils/request'

// 获取当前考试信息
export function getSessionsInfo() {
  return request({
    url: '/student/sessionsInfo',
    method: 'get',
    params: {

    },
  })
}

// 随机生成考卷返回客户端考试
export function getPaperInfo(params) {
  return request({
    url: '/student/questionList',
    method: 'get',
    params: params,
  })
}

// 单个答案提交
export function singleSubmitAnswer(data) {
  return request({
    url: '/student/singleSubmitAnswer',
    method: 'post',
    data,
  })
}

// 交卷
export function submitPaper(data) {
  return request({
    url: '/student/completeTest',
    method: 'post',
    data,
  })
}
