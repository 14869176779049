// 测试状态
export const EXAM = {
  init: {
    val: 'init',
    txt: '未开始',
  },
  pass: {
    val: 'pass',
    txt: '通过',
  },
  fail: {
    val: 'fail',
    txt: '未通过',
  },
}
// 课程配置类型
export const BIZLINE = {
  seven_member_new: {
    val: 'seven_member_new',
    txt: '施工现场专业人员新证',
    templateUrl: 'template/学员批量导入模板-新证16.xlsx',
  },
  seven_member_continue: {
    val: 'seven_member_continue',
    txt: '施工现场专业人员继教',
    templateUrl: 'template/学员批量导入模板-继教.xlsx',
  },
  constructorII: {
    val: 'constructorII',
    txt: '二级建造师继续教育',
    templateUrl: 'template/学员批量导入模板-二建继教60.xlsx',
  },
  builder_skill: {
    val: 'builder_skill',
    txt: '建筑技能工人',
    templateUrl: 'template/学员批量导入模板-技能工人24.xlsx',
  },
  special_new: {
    val: 'special_new',
    txt: '特种作业人员考前学习',
    templateUrl: 'template/学员批量导入模板-特种新证16.xlsx',
  },
  special_continue: {
    val: 'special_continue',
    txt: '特种作业人员继续教育',
    templateUrl: 'template/学员批量导入模板-特种继教8.xlsx',
  },
  safety_management_new: {
    val: 'safety_management_new',
    txt: '建筑施工安管人员新证',
    templateUrl: 'template/学员批量导入模板-安管新证29.xlsx',
  },
  safety_management_continue: {
    val: 'safety_management_continue',
    txt: '建筑施工安管人员继教',
    templateUrl: 'template/学员批量导入模板-安管继教24.xlsx',
  },
}
// 新继类型
export const NEWCON = {
  seven: {
    val: 'seven',
    txt: '施工现场专业人员',
  },
  special: {
    val: 'special',
    txt: '特种作业人员',
  },
  safety: {
    val: 'safety',
    txt: '建筑施工安管人员',
  },
}

// 用户类型
export const USER_TYPE = {
  government: {
    val: 'government',
    txt: '监管',
  },
  organization: {
    val: 'organization',
    txt: '机构',
  },
  student: {
    val: 'student',
    txt: '学员',
  },
  exam: {
    val: 'exam',
    txt: '考试学员',
  },
}

// 试卷题目类型
export const QUESTION_TYPE = {
  SingleChoice: {
    val: 'SingleChoice',
    txt: '单选题',
  },
  MultiChoice: {
    val: 'MultiChoice',
    txt: '多选题',
  },
  TrueFalse: {
    val: 'TrueFalse',
    txt: '判断题',
  },
  example: {
    val: 'example',
    txt: '案例题',
  },
  Completion: {
    val: 'Completion',
    txt: '问答题',
  },
  SingleChoiceWithPic: {
    val: 'SingleChoiceWithPic',
    txt: '单选图片题',
  },
  MultiChoiceWithPic: {
    val: 'MultiChoiceWithPic',
    txt: '多选图片题',
  },
}

// 试卷类型
export const PAPER_TYPE = {
  safety: {
    val: 'safety',
    txt: '安全知识',
  },
  theory: {
    val: 'theory',
    txt: '专业知识',
  },
}

