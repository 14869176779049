// import { _local } from '@/utils/local'
// import { USERINFO } from '@/const/sys'
import { mapGetters } from 'vuex'
import { getUserInfo } from '@/utils/auth'

export default {
  data() {
    return {
      isEditPassword: false,
      isEditStudentInfo: false,
      isCertificate: false,
      isAuth: false,
      homePage: '',
      authQrcode: null,
    }
  },
  computed: {
    ...mapGetters([
      'userInfo',
    ]),
    dropdownMenus() {
      const menusInfo = {
        titleName: '',
        menus: [],
      }

      return menusInfo
    },
  },
  created() {
    // this.bindDropdownList()
  },
  methods: {

  },
}
