const state = {
  // 计划ID
  planId: null,
  bizLine: null,
}
const mutations = {
  savePlanId: (state, planId) => {
    state.planId = planId
  },
  saveBizLine: (state, bizLine) => {
    state.bizLine = bizLine
  },
}
const getter = {
  count: state => state,
}
export default {
  namespaced: true,
  state,
  mutations,
  getter,
}
