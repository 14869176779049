var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _vm._m(0),
      _c(
        "el-form",
        {
          ref: "loginForm",
          staticClass: "login-form",
          attrs: {
            model: _vm.loginForm,
            rules: _vm.loginRules,
            autocomplete: "on",
            "label-position": "left",
          },
        },
        [
          !_vm.selectPick
            ? _c(
                "div",
                { staticClass: "form-content" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "username" } },
                    [
                      _c(
                        "span",
                        { staticClass: "svg-container" },
                        [_c("svg-icon", { attrs: { "icon-class": "user" } })],
                        1
                      ),
                      _c("el-input", {
                        ref: "username",
                        attrs: {
                          placeholder: "用户名",
                          name: "username",
                          type: "text",
                          tabindex: "1",
                          autocomplete: "on",
                        },
                        model: {
                          value: _vm.loginForm.username,
                          callback: function ($$v) {
                            _vm.$set(_vm.loginForm, "username", $$v)
                          },
                          expression: "loginForm.username",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        content: "Caps lock is On",
                        placement: "right",
                        manual: "",
                      },
                      model: {
                        value: _vm.capsTooltip,
                        callback: function ($$v) {
                          _vm.capsTooltip = $$v
                        },
                        expression: "capsTooltip",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "password" } },
                        [
                          _c(
                            "span",
                            { staticClass: "svg-container" },
                            [
                              _c("svg-icon", {
                                attrs: { "icon-class": "password" },
                              }),
                            ],
                            1
                          ),
                          _c("el-input", {
                            key: _vm.passwordType,
                            ref: "password",
                            attrs: {
                              type: _vm.passwordType,
                              placeholder: "密码",
                              name: "password",
                              tabindex: "2",
                              autocomplete: "on",
                            },
                            on: {
                              blur: function ($event) {
                                _vm.capsTooltip = false
                              },
                            },
                            nativeOn: {
                              keyup: [
                                function ($event) {
                                  return _vm.checkCapslock($event)
                                },
                                function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.handleLogin($event)
                                },
                              ],
                            },
                            model: {
                              value: _vm.loginForm.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.loginForm, "password", $$v)
                              },
                              expression: "loginForm.password",
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "show-pwd",
                              on: { click: _vm.showPwd },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  "icon-class":
                                    _vm.passwordType === "password"
                                      ? "eye"
                                      : "eye-open",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btnStart",
                          attrs: { loading: _vm.loading, type: "primary" },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.handleLogin($event)
                            },
                          },
                        },
                        [_vm._v("开始考试 ")]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "countDown" }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm.selectPick
        ? _c("div", { staticClass: "tipsDiv" }, [
            _c(
              "div",
              {
                staticClass:
                  "flex flex-row align-center justify-between popDivTitle",
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "font-size": "17px",
                      "font-weight": "500",
                      "text-align": "left",
                    },
                  },
                  [_vm._v("请选择科目考试")]
                ),
                _c("div", [
                  _c("img", {
                    attrs: { src: require("@/assets/images/ico_close.png") },
                    on: { click: _vm.closePick },
                  }),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "select-content" },
              _vm._l(_vm.examItems, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass:
                      "flex flex-row align-center justify-between contentDiv",
                  },
                  [
                    _c("div", [
                      _c("div", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v(_vm._s(item.paperTitle)),
                      ]),
                      _c("div", { staticClass: "examTime" }, [
                        _vm._v(
                          " 考试时间：" +
                            _vm._s(item.examStartTime) +
                            " 至 " +
                            _vm._s(item.examEndTime) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-size": "14px",
                            "font-weight": "400",
                            "line-height": "20px",
                            color: "#0486FF",
                            cursor: "pointer",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.selectExam(item.examRecordId)
                            },
                          },
                        },
                        [_vm._v("进入考试 >")]
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-container" }, [
      _c("div", { staticClass: "title" }, [_vm._v("化工培训")]),
      _c("div", { staticClass: "title2" }, [_vm._v("—— 考务系统 ——")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }