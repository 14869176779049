<template>
  <div class="result">
    <!--    <div class="header">-->
    <!--      <el-button size="mini" @click="toBack">返回</el-button>-->
    <!--    </div>-->
    <div class="content">
      <!--      <div :class="textResult.result === EXAM.fail.val ? EXAM.fail.val : EXAM.pass.val">{{ textResult.result === EXAM.fail.val ? '很遗憾，测试结果不通过' : '恭喜，您通过了测试！' }}</div>-->
      <div class="second">
        <!--       测试课程：{{ textResult.testName }}-->
        {{ textResult.result === EXAM.pass.val?'恭喜，您的练习结果通过':'抱歉，您没有通过考试' }}
        <i class="icon el-icon-check" />
        <!--        <div>测试时间：{{ textResult.finishTime }}</div>-->
        <!--        <div>测试分数：{{ textResult.score }}</div>-->
      </div>
      <div class="exam-info">
        <!--        <div class="left-box">-->
        <!--          <div class="userImg"></div>-->
        <!--          <div class="stuName">学员123</div>-->
        <!--          <div class="examCardNum">-->
        <!--            <span>准考证号：</span>-->
        <!--            <span>123456789</span>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="result-box">
          <div class="title">试卷得分</div>
          <div class="exam-score"><span class="score">{{ textResult.examScore }}</span>分</div>
        </div>
        <div class="exam-result">
          <div class="result-item hook">
            <span>答对：</span><span>{{ textResult.countCorrect }}题</span>
          </div>
          <div class="result-item fork">
            <span>答错：</span><span>{{ textResult.countWrong }}题</span>
          </div>
          <div class="result-item">
            <span>未答：</span><span>{{ textResult.countEmpty }}题</span>
          </div>
        </div>
      </div>
      <div style="margin-top: 80px">
        <el-button type="primary" style="width:30%;margin-bottom:30px;">我知道了</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { EXAM } from '@/const/sys'
export default {
  name: 'ExaminationResultTab',
  data() {
    return {
      EXAM,
    }
  },
  computed: {
    ...mapState('stuExam', {
      textResult: state => state.textResult,
    }),
  },
  created() {
    // console.log(this.textResult, 11111)
  },
  mounted() {
  },
  methods: {
    toBack() {
      this.$emit('toList')
    },
  },
}
</script>

<style lang="scss" scoped>
@import "exam-result";
</style>
