var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" },
      }),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "hover" },
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { color: "#B5B5B6" },
                  attrs: { type: "text", icon: "el-icon-user-solid" },
                },
                [_vm._v(_vm._s("你好 " + _vm.dropdownMenus.titleName))]
              ),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                _vm._l(_vm.dropdownMenus.menus, function (item, index) {
                  return _c(
                    "el-dropdown-item",
                    {
                      key: index,
                      nativeOn: {
                        click: function ($event) {
                          return item.click($event)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(item.title) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }