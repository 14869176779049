module.exports = {
  jwt: {
    jwt_secret: 'zxjy2024',
    jwt_expires: 60,
  },
  // 学员端图片存储路径
  studentImgPath: {
    headImgPath: 'edu/stu/headImg/', // 头像存储
    videoImgPath: 'edu/stu/videoImg/', // 视频图片存储
  },
  siteTitle: '住建领域从业人员在线教育平台',
}
